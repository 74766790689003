'use client';
import { useFooter } from '@/entities/footer/model';
import { IntroductionText } from './IntroductionText';
import { Sections } from './Sections';
import { Logos } from './Logos';
import { IconsBlock } from './IconsBlock';
import { Copyright } from './Copyright';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSidebar } from '@/entities/sidebar';

const hideFooterPaths = ['/games/game/', '/profile'];

const Footer: React.FC = () => {
  const {
    loading,
    footerText,
    sections,
    partnerLogos,
    paymentLogos,
    mobileAppsList,
    socialNetworksList,
    legalIconsList
  } = useFooter();

  const { overlaySidebarPath } = useSidebar();

  const pathname = usePathname();
  const [hideFooter, setHideFooter] = useState(false);
  const withOverlaySidebar = overlaySidebarPath.some((path) => pathname.includes(path));

  useEffect(() => {
    let hide = false;
    hideFooterPaths.forEach((hidePath) => {
      if (pathname.startsWith(hidePath)) {
        hide = true;
      }
    });
    setHideFooter(hide);
  }, [pathname]);

  if (hideFooter) {
    return null;
  }
  if (loading) {
    return null;
  }
  if (
    (!footerText || (!footerText.introductionText && !footerText?.copyrightText)) &&
    (!sections || sections.length === 0) &&
    (!partnerLogos || partnerLogos.length === 0) &&
    (!paymentLogos || paymentLogos.length === 0) &&
    (!mobileAppsList || mobileAppsList.length === 0) &&
    (!socialNetworksList || socialNetworksList.length === 0) &&
    (!legalIconsList || legalIconsList.length === 0)
  ) {
    return null;
  }

  const introductionTextSettings = footerText?.introductionText;
  const copyRightText = footerText?.copyrightText;

  return (
    <footer
      className={classNames(
        'rounded-t-3 bg-background-body px-2 pb-[86px] font-roboto md:px-3 md:pb-2',
        !withOverlaySidebar && 'md:mr-3'
      )}
      data-testid="footer"
    >
      <IntroductionText {...introductionTextSettings} />
      <Sections sections={sections} />
      <Logos partnerLogos={partnerLogos} paymentLogos={paymentLogos} />
      <IconsBlock mobileApps={mobileAppsList} socialNetworks={socialNetworksList} />
      <Copyright legalIcons={legalIconsList} copyrightText={copyRightText} />
    </footer>
  );
};

export default Footer;
