import { Device, MenuItem, PageItem } from './types';

export function filterMenuItems(sections: unknown, device: Device, loggedIn?: boolean) {
  const checkMenuItemAccess = (section: MenuItem) => {
    const deviceFilter = section.device === 'ALL' || section.device === device;
    const authStatusFilter =
      section.displayFor === 'ALL' ||
      (loggedIn ? section.displayFor === 'LOGGED_IN' : section.displayFor === 'NOT_LOGGED_IN');
    return section.enabled && deviceFilter && authStatusFilter;
  };

  try {
    return Array.isArray(sections)
      ? sections
          .filter((section) => checkMenuItemAccess(section))
          .map((section) => {
            if ('items' in section && Array.isArray(section.items)) {
              return {
                ...section,
                items: (section.items as Array<PageItem>).filter((item) =>
                  checkMenuItemAccess(item)
                )
              };
            }
            return section;
          })
      : [];
  } catch (err) {
    console.error('Sidebar Settings: Invalid Data Type');
    return [];
  }
}
