'use client';

import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const ArrowIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={14} viewBox="0 0 12 12" fill="none" className={className}>
    <path
      d="M1.5 3.75L6 8.25L10.5 3.75"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
