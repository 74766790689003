import { Divider } from '@/entities/sidebar/types';
import classNames from 'classnames';

export const SidebarDivider = ({
  section,
  sidebarVisible = true,
  className
}: {
  section: Divider;
  sidebarVisible?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        'typography-info overflow-hidden text-ellipsis whitespace-nowrap text-text-disabled',
        sidebarVisible && section.name && 'mb-1',
        className
      )}
    >
      {sidebarVisible && section.name}
    </div>
  );
};
