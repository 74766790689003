'use client';

import classNames from 'classnames';
import { NewButton as Button } from '@/shared/ui';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { ButtonMenuItem } from '@/entities/sidebar/types';
import { getAnchorTarget } from '@/shared/lib/helpers/get-anchor-target';
import { SidebarItemName } from './sidebar-item-name';
import { SidebarItemBorder } from './sidebar-item-border';

type Props = {
  section: ButtonMenuItem;
  className?: string;
  onClick?: () => void;
  icon: React.ReactElement;
  hasPageBefore?: boolean;
  hasPageAfter?: boolean;
};

export const FullSidebarButton: React.FC<Props> = ({
  section,
  className,
  onClick,
  icon,
  hasPageAfter,
  hasPageBefore
}) => {
  const { name, route } = section;

  return (
    <Button
      dataTestId={getDataTestId({
        block: 'sidebar',
        nameForTargetElement: 'section',
        modification: name
      })}
      size="custom"
      variant="sidebar"
      className={classNames(
        'typography-button-regular relative w-full min-w-0 rounded-2 px-2 py-[14px]',
        'flex items-center !justify-start gap-[11px] md:gap-[14px]',
        hasPageBefore && '!rounded-t-0',
        hasPageAfter && '!rounded-b-0',
        className
      )}
      onClick={onClick}
      href={route}
      target={route && getAnchorTarget(route)}
    >
      {icon}
      <SidebarItemName
        name={name}
        annotation={'annotation' in section ? section.annotation : undefined}
      />
      {hasPageAfter && <SidebarItemBorder />}
    </Button>
  );
};
