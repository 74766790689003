import classNames from 'classnames';

export const SidebarItemName: React.FC<{
  name: string;
  annotation?: string;
  annotationClassName?: string;
}> = ({ name, annotation, annotationClassName }) => {
  return (
    <div className="flex flex-col items-start gap-1/2 overflow-hidden">
      <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">{name}</span>
      {annotation && (
        <span
          className={classNames(
            'typography-info max-w-full content-start overflow-hidden text-ellipsis whitespace-nowrap text-text-disabled group-hover:md:text-text-brand',
            annotationClassName
          )}
        >
          {annotation}
        </span>
      )}
    </div>
  );
};
