type ContactInfo = {
  email: string;
  whatsapp: {
    label: string;
    url: string;
  };
  telegram: {
    label: string;
    url: string;
  };
};

export const contactInfo: ContactInfo = {
  email: 'support@betfm.com',
  whatsapp: { label: 'whatsapp', url: '/404' }, // 'https://api.whatsapp.com/send?phone=XXXXXXXXXX'
  telegram: { label: 'telegram', url: '/404' } //  'https://t.me/+XXXXXXXXXX'
};
