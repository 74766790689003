'use client';

import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { SidebarContent } from './components/sidebar-content';
import { MenuItem } from '@/entities/sidebar/types';
import { usePathname } from 'next/navigation';
import { useSidebar } from '@/entities/sidebar';
import { useOutsideClick } from '@/shared/lib/use-outside-click';

export const OverlaySidebar = ({ items }: { items: MenuItem[] }) => {
  const path = usePathname();
  const { overlaySidebarVisible, toggleOverlaySidebar } = useSidebar();
  const overlaySidebarRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(
    overlaySidebarRef,
    () => toggleOverlaySidebar(false),
    '[data-testid="header-sidebar-toggle"]'
  );

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  useEffect(() => {
    return () => toggleOverlaySidebar(false);
  }, [path]);

  if (!overlaySidebarVisible) {
    return null;
  }

  return (
    <>
      <div className="blur-backgroud z-top overflow-hidden"></div>
      <div
        className="sidebar fixed left-0 top-[--height-header] z-top3 h-full overflow-scroll"
        ref={overlaySidebarRef}
      >
        <div
          className={classNames(
            'min-h-full w-[323px] rounded-r-3 bg-background-main pl-3 pr-2 pt-2'
          )}
        >
          <div className="w-full overflow-auto pb-[116px]">
            <SidebarContent items={items} />
          </div>
        </div>
      </div>
    </>
  );
};
