'use client';

import React, { useMemo } from 'react';
import { useSidebar } from '@/entities/sidebar';
import { usePathname } from 'next/navigation';
import { MenuItem } from '@/entities/sidebar/types';
import { OverlaySidebar } from './overlay-sidebar';
import { useProfile } from '@/entities/profile';
import { filterMenuItems } from '@/entities/sidebar/utils';
import { DefaultSidebar } from './default-sidebar';
import { ExpandProvider } from './components/expandContext/expand-provider';

type Props = {
  className?: string;
  items: MenuItem[];
};

export const SidebarContainer = ({ items }: Props) => {
  const { overlaySidebarPath } = useSidebar();
  const { profile, ready } = useProfile();

  const filteredItems = useMemo(() => {
    return filterMenuItems(items, 'DESKTOP', !!profile?.id && ready);
  }, [items, ready, profile?.id]);

  const pathname = usePathname();
  const withOverlaySidebar = overlaySidebarPath.some((path) => pathname.includes(path));

  if (withOverlaySidebar) {
    return <OverlaySidebar items={filteredItems} />;
  }

  return (
    <ExpandProvider>
      <DefaultSidebar items={filteredItems} />
    </ExpandProvider>
  );
};
