import classNames from 'classnames';
import { Divider } from './Divider';
import { getDataTestId } from '@/shared/lib/get-data-testid';

type Logo = {
  displayOn: 'ALL' | 'DESKTOP' | 'MOBILE';
  icon: string;
  enabled: boolean;
  route?: string;
};

type Props = {
  mobileApps: Logo[];
  socialNetworks: Logo[];
};

export const IconsBlock: React.FC<Props> = ({ mobileApps, socialNetworks }) => {
  if (!mobileApps && !socialNetworks) return null;
  const enabledMobileApps = mobileApps.filter((logo) => logo.enabled);
  const enabledSocialNetworks = socialNetworks.filter((logo) => logo.enabled);
  if (enabledMobileApps.length === 0 && enabledSocialNetworks.length === 0) return null;
  return (
    <>
      <div className="md:flex:row mt-3 flex flex-col flex-wrap items-start justify-between md:flex-row md:items-center">
        <div className="grid grow grid-cols-2 flex-wrap items-center justify-start gap-1 md:flex md:gap-3">
          {enabledMobileApps.map((logo, index) => (
            <div
              key={index}
              className={classNames('flex h-5 items-center justify-center', {
                hidden:
                  (logo.displayOn === 'DESKTOP' && window.innerWidth < 980) ||
                  (logo.displayOn === 'MOBILE' && window.innerWidth >= 980)
              })}
            >
              <a href={logo.route} target="_blank" rel="noreferrer">
                <img
                  src={logo.icon}
                  alt="Mobile App Logo"
                  className="max-h-5"
                  data-testid={getDataTestId({
                    block: 'footer',
                    nameForTargetElement: 'mobileAppLogo',
                    modification: String(index)
                  })}
                />
              </a>
            </div>
          ))}
        </div>
        <div className="mt-2 flex flex-wrap items-center justify-start gap-1 md:mt-0 md:gap-3">
          {enabledSocialNetworks.map((logo, index) => (
            <div
              key={index}
              className={classNames('flex h-5 items-center justify-center', {
                hidden:
                  (logo.displayOn === 'DESKTOP' && window.innerWidth < 980) ||
                  (logo.displayOn === 'MOBILE' && window.innerWidth >= 980)
              })}
            >
              <a href={logo.route} target="_blank" rel="noreferrer">
                <img
                  src={logo.icon}
                  alt="Social Network Logo"
                  className="max-h-5"
                  data-testid={getDataTestId({
                    block: 'footer',
                    nameForTargetElement: 'socialNetworkLogo',
                    modification: String(index)
                  })}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Divider className="mt-3" />
    </>
  );
};
