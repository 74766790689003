'use client';

import classNames from 'classnames';
import { NewButton as Button } from '@/shared/ui';
import { ArrowIcon } from '../../icons';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { PageTreeItem } from '@/entities/sidebar/types';
import { ReactNode } from 'react';
import { getAnchorTarget } from '@/shared/lib/helpers/get-anchor-target';
import { SidebarItemName } from './sidebar-item-name';
import { SidebarItemBorder } from './sidebar-item-border';

type Props = {
  section: PageTreeItem;
  onExpand: () => void;
  expanded: boolean;
  icons: { main: ReactNode; items: Record<string, ReactNode> };
  hasPageBefore?: boolean;
  hasPageAfter?: boolean;
};

export const FullSidebarAccordion: React.FC<Props> = ({
  section,
  expanded,
  onExpand,
  icons,
  hasPageBefore,
  hasPageAfter
}) => {
  const { name, annotation, route, items } = section;

  const onArrowClick = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    e.preventDefault();
    onExpand();
  };

  return (
    <div
      className={classNames(
        'relative min-w-0 rounded-2 bg-background-body',
        'typography-mobile-button-small-semibold',
        'md:typography-button-regular',
        hasPageBefore && 'rounded-t-0',
        hasPageAfter && 'rounded-b-0'
      )}
    >
      <div
        data-testid={getDataTestId({
          block: 'sidebar',
          nameForTargetElement: 'section',
          modification: name
        })}
      >
        <Button
          href={route}
          size="custom"
          variant="sidebar"
          disabled={!!route && expanded}
          className={classNames(
            'min-h-[52px] w-full min-w-0 justify-between rounded-2 px-2 py-[12px] md:py-[14px]',
            expanded && 'rounded-b-none bg-background-card !text-text-brand',
            !route && 'pointer-events-none'
          )}
          target={route && getAnchorTarget(route)}
        >
          <div className="flex w-full items-center gap-[11px] overflow-hidden md:gap-[14px]">
            {icons.main}
            <SidebarItemName
              name={name}
              annotation={annotation}
              annotationClassName={classNames(expanded && '!text-text-brand')}
            />
            <span onClick={onArrowClick} className="pointer-events-auto ml-auto flex-shrink-0">
              {expanded ? <ArrowIcon className="rotate-180" /> : <ArrowIcon />}
            </span>
          </div>
        </Button>
      </div>
      <div
        className={classNames(
          'overflow-hidden rounded-b-2',
          'transition-height duration-300 ease-in',
          expanded ? 'max-h-screen' : 'max-h-0'
        )}
      >
        {items.map(({ id, name, route }, idx) => {
          const lastItem = idx === items.length - 1;
          return (
            <Button
              size="custom"
              variant="sidebar"
              key={id}
              className="relative h-[42px] w-full justify-between whitespace-nowrap px-2 py-[12px] md:h-[52px] md:pt-[14px]"
              href={route}
              target={getAnchorTarget(route)}
            >
              <div className="flex items-center gap-[11px] md:gap-[14px]">
                {icons.items[id]}
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">{name}</span>
              </div>
              {!lastItem && <SidebarItemBorder />}
            </Button>
          );
        })}
      </div>
      {hasPageAfter && <SidebarItemBorder />}
    </div>
  );
};
