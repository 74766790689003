import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/apps/main/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/main/src/features/home/ui/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["usePayment"] */ "/app/apps/main/src/features/wallet/payment/lib/use-payment.ts");
;
import(/* webpackMode: "eager", webpackExports: ["DepositMobile"] */ "/app/apps/main/src/features/wallet/payment/ui/deposit-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DepositResultMobile"] */ "/app/apps/main/src/features/wallet/payment/ui/deposit-result-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DepositResult"] */ "/app/apps/main/src/features/wallet/payment/ui/deposit-result.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FailDepositOpenModal"] */ "/app/apps/main/src/features/wallet/payment/ui/fail-deposit-open-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalDepositDesktop"] */ "/app/apps/main/src/features/wallet/payment/ui/modal-deposit-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalWithdrawalDesktop"] */ "/app/apps/main/src/features/wallet/payment/ui/modal-withdrawal-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalWithdrawalWithKycCheckDesktop"] */ "/app/apps/main/src/features/wallet/payment/ui/modal-withdrawal-with-kyc-check-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentModalController"] */ "/app/apps/main/src/features/wallet/payment/ui/payment-modal-controller.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithdrawalMobileWithKycCheck"] */ "/app/apps/main/src/features/wallet/payment/ui/withdrawal-mobile-with-kyc-check.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithdrawalMobile"] */ "/app/apps/main/src/features/wallet/payment/ui/withdrawal-mobile.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/src/shared/assets/images/logo-betfm.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/apps/main/src/shared/lib/apollo/apolloWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/src/shared/lib/toast/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/apps/main/src/widgets/header/ui/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarContainer"] */ "/app/apps/main/src/widgets/sidebar/desktop/sidebar-container.tsx");
