'use client';

import { Tooltip } from 'react-tooltip';

type Props = {
  id: string;
  name: string;
};

export const SidebarTooltip: React.FC<Props> = ({ id, name }) => {
  return (
    <Tooltip
      className="md:zoom-115 z-2 !flex flex-col gap-xxs !rounded-xxs !bg-button-sidebarActive !p-xs text-text-primary opacity-0 shadow-[0_0_10px_0_#0D0D0D80]"
      classNameArrow="!w-[8.5px] !h-[8.5px] !rounded-br-[2px]"
      id={id}
      place="right"
      positionStrategy="fixed"
    >
      {name}
    </Tooltip>
  );
};
