import { contactInfo } from '@/entities/contacts-info';
import { TelegramContactIcon, WhatsappIcon } from '@/shared/ui';

export const CONTACT_LINKS: { href: string; icon: React.FC; title: string }[] = [
  {
    title: 'whatsapp',
    href: contactInfo.whatsapp.url,
    icon: WhatsappIcon
  },
  {
    title: 'telegram',
    href: contactInfo.telegram.url,
    icon: TelegramContactIcon
  }
];

export const onZendeskChatOpen = () => {
  try {
    if (window.zE) {
      window.zE('messenger', 'open');
    } else {
      console.error('Zendesk is not available.');
    }
  } catch {
    console.error('Zendesk is not available.');
  }
};
