import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
  size?: number;
};

export const SupportIcon: React.FC<Props> = ({ className, size = 20 }) => (
  <SvgIcon size={size} viewBox="0 0 20 20" className={className} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4165 10.0001C2.4165 5.81192 5.81168 2.41675 9.99984 2.41675C14.188 2.41675 17.5832 5.81192 17.5832 10.0001C17.5832 14.1882 14.188 17.5834 9.99984 17.5834C8.40159 17.5834 6.92089 17.0898 5.69899 16.2468L5.41372 16.05L5.07895 16.1397L2.82829 16.7427L3.47901 14.3142L3.55878 14.0165L3.40619 13.7488C2.77649 12.6438 2.4165 11.3651 2.4165 10.0001ZM9.99984 0.916748C4.98325 0.916748 0.916504 4.98349 0.916504 10.0001C0.916504 11.5205 1.29066 12.9556 1.95236 14.2162L1.08802 17.442C0.918668 18.074 1.49701 18.6524 2.12905 18.483L5.14108 17.6759C6.5465 18.5673 8.21387 19.0834 9.99984 19.0834C15.0164 19.0834 19.0832 15.0167 19.0832 10.0001C19.0832 4.98349 15.0164 0.916748 9.99984 0.916748ZM6.6665 10.8334C7.12674 10.8334 7.49984 10.4603 7.49984 10.0001C7.49984 9.53984 7.12674 9.16675 6.6665 9.16675C6.20627 9.16675 5.83317 9.53984 5.83317 10.0001C5.83317 10.4603 6.20627 10.8334 6.6665 10.8334ZM10.8332 10.0001C10.8332 10.4603 10.4601 10.8334 9.99984 10.8334C9.5396 10.8334 9.1665 10.4603 9.1665 10.0001C9.1665 9.53984 9.5396 9.16675 9.99984 9.16675C10.4601 9.16675 10.8332 9.53984 10.8332 10.0001ZM13.3332 10.8334C13.7934 10.8334 14.1665 10.4603 14.1665 10.0001C14.1665 9.53984 13.7934 9.16675 13.3332 9.16675C12.8729 9.16675 12.4998 9.53984 12.4998 10.0001C12.4998 10.4603 12.8729 10.8334 13.3332 10.8334Z"
      fill="currentColor"
    />
  </SvgIcon>
);
