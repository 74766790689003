import classNames from 'classnames';
import { BannerItem } from '@/entities/sidebar/types';
import Link from 'next/link';
import { getAnchorTarget } from '@/shared/lib/helpers/get-anchor-target';

type Props = {
  section: BannerItem;
  className?: string;
  mobile?: boolean;
  onClose?: () => void;
  showImage?: boolean;
};

export const SidebarBanner: React.FC<Props> = ({
  section,
  className,
  mobile,
  onClose,
  showImage = true
}) => {
  const { route } = section;
  const target = route && getAnchorTarget(route);
  return (
    <Link
      onClick={target !== '_blank' ? onClose : undefined}
      target={target}
      href={route || '#'}
      className={classNames(
        'relative flex h-[90px] w-full rounded-2 bg-[linear-gradient(84.22deg,#753BBD_0%,#254096_100%)]',
        mobile ? 'h-[70px]' : 'h-[90px]',
        !route && 'pointer-events-none',
        className
      )}
    >
      <img
        src={section.icon ?? undefined}
        alt="Sidebar banner"
        className={classNames(
          'absolute left-0 top-0 h-full w-full object-contain object-center',
          showImage ? 'visible opacity-100' : 'invisible opacity-0'
        )}
      />
    </Link>
  );
};
