'use client';

import { useMemo, useState } from 'react';
import { FullSidebarAccordion } from './full-sidebar-accordion';
import { MiniSidebarAccordion } from './mini-sidebar-accordion';
import { PageTreeItem } from '@/entities/sidebar/types';
import { SidebarIcon } from './sidebar-icon';
import { useSidebar } from '@/entities/sidebar';

type Props = {
  section: PageTreeItem;
  sidebarVisible?: boolean;
  hasPageBefore?: boolean;
  hasPageAfter?: boolean;
};

export const SidebarAccordion: React.FC<Props> = ({ section, hasPageBefore, hasPageAfter }) => {
  const { sidebarVisible, overlaySidebarVisible } = useSidebar();
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded((prev) => !prev);
  };

  const icons = useMemo(() => {
    return {
      main: <SidebarIcon src={section.icon} />,
      items: (section.items || []).reduce<Record<string, React.ReactNode>>((acc, item) => {
        acc[item.id] = <SidebarIcon src={item.icon} />;
        return acc;
      }, {})
    };
  }, [section]);

  return (
    <>
      {sidebarVisible || overlaySidebarVisible ? (
        <FullSidebarAccordion
          section={section}
          onExpand={toggleExpansion}
          expanded={expanded}
          icons={icons}
          hasPageBefore={hasPageBefore}
          hasPageAfter={hasPageAfter}
        />
      ) : (
        <MiniSidebarAccordion
          section={section}
          onExpand={toggleExpansion}
          expanded={expanded}
          icons={icons}
          hasPageBefore={hasPageBefore}
          hasPageAfter={hasPageAfter}
        />
      )}
    </>
  );
};
