import { MutableRefObject, useEffect } from 'react';

export const useOutsideClick = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  callback: () => void,
  ignoreClickOnElement?: string
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (ignoreClickOnElement ? !(event.target as Element).closest(ignoreClickOnElement) : true)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, ignoreClickOnElement]);
};
