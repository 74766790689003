import { createContext, ReactNode, useState } from 'react';

type Context = {
  transitionEnd: boolean;
  setTransitionEnd: (value: boolean) => void;
};

export const ExpandContext = createContext<Context>({
  transitionEnd: false,
  setTransitionEnd: () => {}
});

export function ExpandProvider({ children }: { children: ReactNode }) {
  const [transitionEnd, setTransitionEnd] = useState(false);

  return (
    <ExpandContext.Provider value={{ transitionEnd, setTransitionEnd }}>
      {children}
    </ExpandContext.Provider>
  );
}
