'use client';

import { Device } from '@/__generated__/types';
import { sanitizeSVG } from '@/shared/lib/helpers/sanitize-svg';
import classNames from 'classnames';
import { useState, useEffect } from 'react';

type IconProps = {
  src?: string | null;
  className?: string;
  mobile?: boolean;
};

const ICON_FALLBACK = '/sidebar/sidebar.svg';

export function SidebarIcon({ src, className, mobile }: IconProps) {
  const [safeSvg, setSafeSvg] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndSanitizeSVG = async (url: string, isFallback = false) => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch SVG');

        const svgText = await response.text();
        setSafeSvg(sanitizeSVG(svgText, mobile ? Device.Mobile : Device.Desktop));
      } catch (error) {
        console.error(`Error loading SVG from ${url}:`, error);
        if (!isFallback) fetchAndSanitizeSVG(ICON_FALLBACK, true);
      }
    };

    fetchAndSanitizeSVG(src || ICON_FALLBACK);
  }, [src, mobile]);

  return (
    <div
      className={classNames(className, mobile ? 'h-[20px] w-[20px]' : 'h-[24px] w-[24px]')}
      dangerouslySetInnerHTML={{ __html: safeSvg || '' }}
    />
  );
}
