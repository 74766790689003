import { getDataTestId } from '@/shared/lib/get-data-testid';
import { Divider } from './Divider';
import { MobileSection } from './MobileSection';
import Link from 'next/link';

type Link = {
  color: string;
  enabled: boolean;
  name: string;
  route: string;
};

export type Section = {
  enabled: boolean;
  links: Link[];
  name: string;
};

type Props = {
  sections: Section[];
};

export const Sections: React.FC<Props> = ({ sections }) => {
  if (!sections || sections.length === 0) {
    return null;
  }

  const enabledSections = sections.filter((section) => section.enabled);
  const mappedSections = enabledSections.map((section) => {
    const enabledLinks = section.links.filter((link) => link.enabled);
    return {
      ...section,
      links: enabledLinks
    };
  });
  const filteredSections = mappedSections.filter((section) => section.links.length > 0);

  if (filteredSections.length === 0) {
    return null;
  }

  if (window.innerWidth < 980) {
    return (
      <div className="flex flex-col">
        {filteredSections.map((section, index) => (
          <MobileSection key={index} section={section} index={index} />
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between gap-3">
        {filteredSections.map((section, index) => (
          <div
            key={index}
            className="mt-3 grow"
            data-testid={getDataTestId({
              block: 'footer',
              nameForTargetElement: 'section',
              modification: String(index)
            })}
          >
            <div className="text-14 font-bold text-text-disabled md:text-18">{section.name}</div>
            <Divider className="mt-2" />
            <div className="mt-2 flex flex-col gap-2">
              {section.links.map((link, index) => (
                <Link
                  key={index}
                  className="cursor-pointer text-12 font-normal md:text-16"
                  style={{ color: link.color }}
                  href={link.route}
                  target={link.route.startsWith('http') ? '_blank' : '_self'}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Divider className="mt-3" />
    </>
  );
};
