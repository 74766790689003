import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { getProviderNameUrlNormalize } from '@/shared/lib/provider-name-url';
import { RoutesConfig } from '@/shared/routes/config';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';
import { Divider } from './Divider';

type Logo = {
  displayOn: 'ALL' | 'DESKTOP' | 'MOBILE';
  file: string;
  enabled: boolean;
  name: string;
};

type Props = {
  partnerLogos: Logo[];
  paymentLogos: Logo[];
};

export const Logos: React.FC<Props> = ({ partnerLogos, paymentLogos }) => {
  const { push } = useRouter();
  const pathname = usePathname();
  if (!partnerLogos && !paymentLogos) return null;
  const enabledPartnerLogos = partnerLogos.filter((logo) => logo.enabled);
  const enabledPaymentLogos = paymentLogos.filter((logo) => logo.enabled);
  if (enabledPartnerLogos.length === 0 && enabledPaymentLogos.length === 0) return null;

  const handleRedirect = (providerName: string) => {
    if (!pathname.includes(RoutesConfig.casinoGamesByProvider)) {
      safeLocalStorage.setItem(localStorageConsts.providerTriggerURL, pathname);
    }
    push(`${RoutesConfig.casinoGamesByProvider}/${getProviderNameUrlNormalize(providerName)}`);
  };

  return (
    <>
      <div className="md:flex:row mt-2 flex flex-col flex-wrap items-start justify-between  md:mt-0 md:flex-row md:items-center">
        <div className="flex grow flex-wrap items-center justify-start gap-2">
          {enabledPaymentLogos.map((logo, index) => (
            <div
              key={index}
              className={classNames('mt-1 flex h-5 items-center justify-center md:h-[77px]', {
                hidden:
                  (logo.displayOn === 'DESKTOP' && window.innerWidth < 980) ||
                  (logo.displayOn === 'MOBILE' && window.innerWidth >= 980)
              })}
            >
              <img
                src={logo.file}
                alt="Payment Logo"
                className="max-h-5 md:max-h-10"
                data-testid={getDataTestId({
                  block: 'footer',
                  nameForTargetElement: 'paymentLogo',
                  modification: String(index)
                })}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-wrap items-center gap-2 md:justify-start">
          {enabledPartnerLogos.map((partnerLogo, index) => (
            <div
              key={index}
              className={classNames(
                'mt-1 flex h-5 cursor-pointer items-center justify-center md:h-[77px]',
                {
                  hidden:
                    (partnerLogo.displayOn === 'DESKTOP' && window.innerWidth < 980) ||
                    (partnerLogo.displayOn === 'MOBILE' && window.innerWidth >= 980)
                }
              )}
              onClick={() => handleRedirect(partnerLogo.name)}
            >
              <img
                src={partnerLogo.file}
                alt="Partner Logo"
                className="max-h-5 md:max-h-10"
                data-testid={getDataTestId({
                  block: 'footer',
                  nameForTargetElement: 'partnerLogo',
                  modification: String(index)
                })}
              />
            </div>
          ))}
        </div>
      </div>
      <Divider className="mt-1" />
    </>
  );
};
