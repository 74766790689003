import { ButtonMenuItem } from '@/entities/sidebar/types';
import { FullSidebarButton } from './full-sidebar-button';
import { MiniSidebarButton } from './mini-sidebar-button';
import { SidebarIcon } from './sidebar-icon';
import { useMemo } from 'react';
import { useSidebar } from '@/entities/sidebar';
import { SupportIcon } from '../../icons/support';

type Props = {
  section: ButtonMenuItem;
  className?: string;
  onClick?: () => void;
  hasPageBefore?: boolean;
  hasPageAfter?: boolean;
};

export const SidebarButton: React.FC<Props> = ({
  section,
  className,
  onClick,
  hasPageBefore,
  hasPageAfter
}) => {
  const { sidebarVisible, overlaySidebarVisible } = useSidebar();
  const icon = useMemo(() => <SidebarIcon src={section.icon} />, [section]);

  return (
    <>
      {sidebarVisible || overlaySidebarVisible ? (
        <FullSidebarButton
          section={section}
          className={className}
          onClick={onClick}
          icon={section.type === 'SUPPORT' ? <SupportIcon size={24} /> : icon}
          hasPageBefore={hasPageBefore}
          hasPageAfter={hasPageAfter}
        />
      ) : (
        <MiniSidebarButton
          section={section}
          className={className}
          onClick={onClick}
          icon={section.type === 'SUPPORT' ? <SupportIcon size={24} /> : icon}
          hasPageBefore={hasPageBefore}
          hasPageAfter={hasPageAfter}
        />
      )}
    </>
  );
};
