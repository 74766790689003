import { getDataTestId } from '@/shared/lib/get-data-testid';
import classNames from 'classnames';

type Logo = {
  displayOn: 'ALL' | 'DESKTOP' | 'MOBILE';
  icon: string;
  enabled: boolean;
  route: string;
};
type Text = {
  text: string;
  enabled: boolean;
  displayOn: 'ALL' | 'DESKTOP' | 'MOBILE';
};
type Props = {
  copyrightText?: Text;
  legalIcons: Logo[];
};

export const Copyright: React.FC<Props> = ({ copyrightText, legalIcons }) => {
  const enabledLegalIcons = legalIcons ? legalIcons.filter((logo) => logo.enabled) : [];
  if (enabledLegalIcons.length === 0 && !copyrightText) return null;
  return (
    <div
      className={classNames(
        'mt-3 flex items-start justify-between gap-1 md:flex-row md:items-center md:gap-3',
        {
          'flex-row-reverse': enabledLegalIcons.length <= 1,
          'flex-col-reverse': enabledLegalIcons.length > 1
        }
      )}
    >
      <div className="flex grow items-center justify-start">
        {copyrightText?.enabled && (
          <div
            data-testid={getDataTestId({
              block: 'footer',
              nameForTargetElement: 'copyrightText'
            })}
            className={classNames('whitespace-pre text-12 font-normal text-text-disabled', {
              hidden:
                (copyrightText.displayOn === 'DESKTOP' && window.innerWidth < 980) ||
                (copyrightText.displayOn === 'MOBILE' && window.innerWidth >= 980)
            })}
          >
            {copyrightText.text.trim()}
          </div>
        )}
      </div>
      <div className="flex w-max items-center gap-2 md:justify-start">
        {enabledLegalIcons.map((logo, index) => (
          <div
            key={index}
            className={classNames('flex h-6 w-max items-center justify-center', {
              hidden:
                (logo.displayOn === 'DESKTOP' && window.innerWidth < 980) ||
                (logo.displayOn === 'MOBILE' && window.innerWidth >= 980)
            })}
          >
            <a href={logo.route} target="_blank" rel="noreferrer">
              <img
                src={logo.icon}
                alt="Legal Icon"
                className="max-h-6"
                data-testid={getDataTestId({
                  block: 'footer',
                  nameForTargetElement: 'legalIcon',
                  modification: String(index)
                })}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
