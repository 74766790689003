import { gql } from '@apollo/client';

export const footerQuery = gql`
  query Footer {
    footer {
      success
      sections
      footerText
      paymentLogos
      partnerLogos
      mobileAppsList
      socialNetworksList
      legalIconsList
      error {
        message
      }
    }
  }
`;

type Error = {
  success: false;
  error: {
    message: string;
    stack?: string;
  };
};

type Success = {
  success: true;
  sections: string | null;
  footerText: string | null;
  paymentLogos: string | null;
  partnerLogos: string | null;
  mobileAppsList: string | null;
  socialNetworksList: string | null;
  legalIconsList: string | null;
};

export type FooterConfigResponse = {
  footer: Success | Error;
};
