import { useState } from 'react';
import { Section } from './Sections';
import { Divider } from './Divider';
import { ArrowIcon } from '@/shared/ui';
import classNames from 'classnames';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import Link from 'next/link';

type Props = {
  section: Section;
  index: number;
};

export const MobileSection: React.FC<Props> = ({ section, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="mt-3"
      data-testid={getDataTestId({
        block: 'footer',
        nameForTargetElement: 'section',
        modification: String(index)
      })}
    >
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={classNames('text-14 font-bold md:text-18', {
            'text-text-primary': isOpen,
            'text-text-disabled': !isOpen
          })}
        >
          {section.name}
        </div>
        <div className="h-[12px] w-[12px] text-14 font-normal text-text-primary md:text-18">
          <ArrowIcon
            arrowClassName={classNames({
              'stroke-icon-tertiary': !isOpen,
              'stroke-icon-primary': isOpen
            })}
            className={classNames('h-[12px] w-[12px]', {
              'rotate-90': !isOpen,
              'rotate-[270deg]': isOpen
            })}
          />
        </div>
      </div>
      <Divider className="mt-1" />
      {isOpen && (
        <div className="mt-1 flex flex-col gap-[20px]">
          {section.links.map((link, index) => (
            <Link
              key={index}
              className="cursor-pointer text-12 font-normal md:text-16"
              style={{ color: link.color }}
              href={link.route}
              target={link.route.startsWith('http') ? '_blank' : '_self'}
            >
              {link.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
