import { footerQuery, FooterConfigResponse } from './api';
import { useQuery } from '@apollo/client';

export const useFooter = () => {
  const { data, loading } = useQuery<FooterConfigResponse>(footerQuery, {
    fetchPolicy: 'no-cache'
  });

  if (!data?.footer.success) {
    return {
      loading: false,
      sections: [],
      footerText: null,
      paymentLogos: [],
      partnerLogos: [],
      mobileAppsList: [],
      socialNetworksList: [],
      legalIconsList: []
    };
  }

  const sections = data?.footer.sections ? JSON.parse(data?.footer.sections) : [];
  const footerText = data?.footer.footerText ? JSON.parse(data?.footer.footerText) : null;
  const paymentLogos = data?.footer.paymentLogos ? JSON.parse(data?.footer.paymentLogos) : [];
  const partnerLogos = data?.footer.partnerLogos ? JSON.parse(data?.footer.partnerLogos) : [];
  const mobileAppsList = data?.footer.mobileAppsList ? JSON.parse(data?.footer.mobileAppsList) : [];
  const socialNetworksList = data?.footer.socialNetworksList
    ? JSON.parse(data?.footer.socialNetworksList)
    : [];
  const legalIconsList = data?.footer.legalIconsList ? JSON.parse(data?.footer.legalIconsList) : [];

  return {
    loading: loading,
    sections,
    footerText,
    paymentLogos,
    partnerLogos,
    mobileAppsList,
    socialNetworksList,
    legalIconsList
  };
};
