import { SidebarTooltip } from './sidebar-tooltip';
import classNames from 'classnames';
import { NewButton } from '@/shared/ui';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { createPortal } from 'react-dom';
import { MenuItem } from '@/entities/sidebar/types';
import { getAnchorTarget } from '@/shared/lib/helpers/get-anchor-target';

type Props = {
  section: MenuItem;
  className?: string;
  onClick?: () => void;
  icon: React.ReactElement;
  hasPageBefore?: boolean;
  hasPageAfter?: boolean;
};

export const MiniSidebarButton: React.FC<Props> = ({
  section,
  className,
  onClick,
  icon,
  hasPageAfter,
  hasPageBefore
}) => {
  const { name, route, id } = section;

  return (
    <NewButton
      dataTestId={getDataTestId({
        block: 'sidebar',
        nameForTargetElement: 'section',
        modification: name
      })}
      size="custom"
      variant="sidebar"
      className={classNames(
        'typography-button-regular relative h-[52px] w-[47px] rounded-2',
        hasPageBefore && 'rounded-t-0',
        hasPageAfter && 'rounded-b-0',
        className
      )}
      onClick={onClick}
      href={route}
      target={route && getAnchorTarget(route)}
    >
      <span
        className="relative flex h-full w-full items-center justify-center"
        data-tooltip-id={id}
      >
        {icon}
        {createPortal(<SidebarTooltip name={name || ''} id={id} />, document.body)}
      </span>
    </NewButton>
  );
};
