import classNames from 'classnames';
import { SidebarTooltip } from './sidebar-tooltip';
import { NewButton } from '@/shared/ui';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { createPortal } from 'react-dom';
import { PageItem, PageTreeItem } from '@/entities/sidebar/types';
import { ReactNode } from 'react';

type Props = {
  section: PageTreeItem;
  onExpand: () => void;
  expanded: boolean;
  icons: { main: ReactNode; items: Record<string, ReactNode> };
  hasPageBefore?: boolean;
  hasPageAfter?: boolean;
};

export const MiniSidebarAccordion: React.FC<Props> = ({
  section,
  onExpand,
  expanded,
  icons,
  hasPageBefore,
  hasPageAfter
}) => {
  const { id, name, route = '#', items } = section;

  return (
    <div
      className={classNames(
        'relative w-[47px] rounded-2 bg-background-body',
        'typography-mobile-button-small-semibold',
        'md:typography-button-regular',
        hasPageBefore && 'rounded-t-0',
        hasPageAfter && 'rounded-b-0'
      )}
    >
      <div
        onClick={onExpand}
        className="transition-height duration-300 ease-in"
        data-tooltip-id={id}
        data-testid={getDataTestId({
          block: 'sidebar',
          nameForTargetElement: 'section',
          modification: name
        })}
      >
        <NewButton
          href={route}
          size="custom"
          variant="sidebar"
          disabled={expanded}
          className={classNames(
            'flex h-[52px] w-[47px] items-center justify-center rounded-2',
            expanded && 'rounded-b-none bg-background-card text-text-brand'
          )}
        >
          <span className={classNames(expanded && 'text-text-brand')}>
            {icons.main}
            {createPortal(<SidebarTooltip name={name} id={id} />, document.body)}
          </span>
        </NewButton>
      </div>
      <div
        className={classNames(
          `transition-height overflow-hidden duration-300 ease-in ${expanded ? `max-h-screen overflow-visible` : 'max-h-0'}`
        )}
      >
        {items.map((item, idx) => (
          <AccordionItem
            section={item}
            lastItem={idx === items.length - 1}
            key={idx}
            icon={icons.items[item.id]}
          />
        ))}
      </div>
    </div>
  );
};

const AccordionItem = ({
  section,
  lastItem,
  icon
}: {
  section: PageItem;
  lastItem: boolean;
  icon: ReactNode;
}) => {
  const { id, name, route = '#' } = section;

  return (
    <div data-tooltip-id={id}>
      <NewButton
        size="custom"
        variant="sidebar"
        className={classNames(lastItem && 'rounded-b-2', 'h-[52px]')}
        href={route}
      >
        {icon}
        {createPortal(<SidebarTooltip name={name} id={id} />, document.body)}
      </NewButton>
    </div>
  );
};
