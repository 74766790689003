import { getDataTestId } from '@/shared/lib/get-data-testid';
import { Divider } from './Divider';

type Props = {
  text: string;
  enabled: boolean;
  displayOn: 'ALL' | 'DESKTOP' | 'MOBILE';
};

export const IntroductionText: React.FC<Props> = ({ text, enabled, displayOn }) => {
  if (!enabled) {
    return null;
  }

  if (displayOn === 'DESKTOP' && window.innerWidth < 980) {
    return null;
  }

  if (displayOn === 'MOBILE' && window.innerWidth >= 980) {
    return null;
  }

  return (
    <div className="w-full">
      <div
        className="mt-3 whitespace-pre-line text-14 font-normal text-text-disabled md:mt-4 md:text-16"
        data-testid={getDataTestId({
          block: 'footer',
          nameForTargetElement: 'introductionText'
        })}
      >
        {text.trim()}
      </div>
      <Divider className="mt-3 hidden md:block" />
    </div>
  );
};
