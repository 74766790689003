'use client';

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SidebarContent } from './components/sidebar-content';
import { MenuItem } from '@/entities/sidebar/types';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { useSidebar } from '@/entities/sidebar';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { useExpandContext } from './components/expandContext/use-expand-context';

export const DefaultSidebar = ({ className, items }: { className?: string; items: MenuItem[] }) => {
  const { sidebarVisible, setInitialSidebarVisible } = useSidebar();

  const [isMounted, setIsMounted] = useState(false);
  const { setTransitionEnd } = useExpandContext();

  const onSidebarTransitionEnd = (e: React.TransitionEvent<HTMLElement>) => {
    if (e.propertyName === 'width') {
      if (sidebarVisible) {
        setTransitionEnd(true);
      } else {
        setTransitionEnd(false);
      }
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (sidebarVisible === null) {
      const visible = safeLocalStorage.getItem('sidebarVisible') === 'true';
      setInitialSidebarVisible(visible);
      setTransitionEnd(visible);
    }
  }, [sidebarVisible]);

  return (
    <nav
      data-testid={getDataTestId({
        block: 'sidebar',
        nameForTargetElement: 'container'
      })}
      className={classNames(
        'sidebar',
        sidebarVisible ? 'w-[323px]' : 'w-[87px]',
        isMounted && 'transition-width duration-500',
        'bg-gray-700  z-top3 flex flex-col items-center pb-10 pl-3 pr-2 pt-2 ',
        'h-full overflow-y-auto overflow-x-visible',
        className
      )}
      onTransitionEnd={onSidebarTransitionEnd}
    >
      {sidebarVisible !== null && (
        <div className="w-full">
          <SidebarContent sidebarVisible={sidebarVisible} items={items} />
        </div>
      )}
    </nav>
  );
};
