import { Device } from '@/__generated__/types';

/**
 * Sanitizes an SVG string before rendering
 */

export const sanitizeSVG = (svg: string, device?: Device): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svg, 'image/svg+xml');

  if (doc.querySelector('parsererror')) {
    console.warn('Invalid SVG content detected');
    return '';
  }

  // Remove all <script>, <style>, and <foreignObject> elements
  const unsafeTags = ['script', 'style', 'foreignObject'];
  unsafeTags.forEach((tag) => {
    doc.querySelectorAll(tag).forEach((el) => el.remove());
  });

  // Remove all event handler attributes (e.g., onmouseover, onclick)
  doc.querySelectorAll('*').forEach((el) => {
    for (const attr of [...el.attributes]) {
      if (attr.name.startsWith('on')) {
        el.removeAttribute(attr.name);
      }
    }
  });

  // Set fill="currentColor", stroke="currentColor"
  doc.querySelectorAll('path, circle, rect, polygon, ellipse').forEach((el) => {
    if (el.hasAttribute('fill') && el.getAttribute('fill') !== 'none') {
      el.setAttribute('fill', 'currentColor');
    }

    if (el.hasAttribute('stroke') && el.getAttribute('stroke') !== 'none') {
      el.setAttribute('stroke', 'currentColor');
    }
  });

  const svgElement = doc.querySelector('svg');
  if (!svgElement) {
    console.warn('No <svg> element found in the provided SVG');
    return '';
  }

  // Set SVG width & height
  svgElement.setAttribute('width', device === Device.Mobile ? '20' : '24');
  svgElement.setAttribute('height', device === Device.Mobile ? '20' : '24');

  return new XMLSerializer().serializeToString(doc);
};
