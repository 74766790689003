import React from 'react';
import { SidebarAccordion } from './sidebar-accordion';
import { SidebarButton } from './sidebar-button';
import { SidebarBanner } from './sidebar-banner';
import { MenuItem } from '@/entities/sidebar/types';
import { SidebarDivider } from './sidebar-divider';
import { useExpandContext } from './expandContext/use-expand-context';
import { useSidebar } from '@/entities/sidebar';
import { onZendeskChatOpen } from '../../lib';

type Props = {
  sidebarVisible?: boolean;
  items: MenuItem[];
};

export const SidebarContent = ({ sidebarVisible = true, items }: Props) => {
  const { transitionEnd } = useExpandContext();
  const { overlaySidebarVisible } = useSidebar();

  return (
    <>
      {items.map((section, idx) => (
        <React.Fragment key={section.id}>
          {(() => {
            const hasPageBefore = ['PAGE', 'PAGE_TREE'].includes(items[idx - 1]?.type);
            const hasPageAfter = ['PAGE', 'PAGE_TREE'].includes(items[idx + 1]?.type);

            switch (section.type) {
              case 'BANNER':
                return (
                  <>
                    {sidebarVisible ? (
                      <SidebarBanner
                        showImage={overlaySidebarVisible || transitionEnd}
                        section={section}
                        className="my-2 first:mt-0"
                      />
                    ) : (
                      <div className="mt-2 first:mt-0"></div>
                    )}
                  </>
                );
              case 'DIVIDER':
                return (
                  <SidebarDivider
                    section={section}
                    sidebarVisible={sidebarVisible}
                    className="mt-2 first:mt-0"
                  />
                );
              case 'PAGE':
                return (
                  <SidebarButton
                    section={section}
                    hasPageBefore={hasPageBefore}
                    hasPageAfter={hasPageAfter}
                  />
                );
              case 'PAGE_TREE':
                return (
                  <SidebarAccordion
                    section={section}
                    hasPageBefore={hasPageBefore}
                    hasPageAfter={hasPageAfter}
                  />
                );
              case 'SUPPORT':
                return (
                  <SidebarButton
                    className="my-2 !text-text-brand first:mt-0 hover:!text-button-brand-active"
                    section={section}
                    onClick={onZendeskChatOpen}
                  />
                );
              // TODO: add language component
              case 'LANGUAGE':
              default:
                return <></>;
            }
          })()}
        </React.Fragment>
      ))}
    </>
  );
};
